<template>
  <form
    autocomplete="off"
    @submit.prevent="submit"
  >
    <div class="row">
      <loading-notification :show="loading" />
    </div>

    <div class="row">
      <div class="flex xs12 md8">
        <h6>{{ $t('users.tabs.avatar.preview') }}</h6>
        <small>{{ $t('users.tabs.avatar.recommendations') }}</small>
        <picture-input
          ref="avatar"
          width="300"
          height="300"
          margin="16"
          size="10"
          :crop="false"
          :prefill="prefill.avatar"
          button-class="va-button va-button--flat color--primary"
          :custom-strings="imageTranslations"
          @change="onChange('avatar', 'avatar')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-button
          v-if="prefill.avatar && untouched"
          color="danger"
          type="button"
          :disabled="loading"
          @click.prevent="removeImage()"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-times"
          >
            {{ $t('layout.form.delete') }}
          </text-loading>
        </va-button>
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
const PictureInput = () => import(/* webpackPrefetch: true */ 'vue-picture-input')

export default {
  name: 'change-avatar',
  components: {
    PictureInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    user: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  data () {
    return {
      prefill: {
        avatar: null,
      },
      images: {
        avatar: null,
      },
      untouched: true,
      imageTranslations: {
        upload: this.$t('layout.images.upload_error'),
        drag: this.$t('layout.images.drag'),
        tap: this.$t('layout.images.tap'),
        change: this.$t('layout.images.change'),
        remove: this.$t('layout.images.remove'),
        select: this.$t('layout.images.select'),
        selected: this.$t('layout.images.selected'),
        fileSize: this.$t('layout.images.file_size', { size: 10 }),
        fileType: this.$t('layout.images.file_type'),
        aspect: this.$t('layout.images.aspect'),
      },
    }
  },
  watch: {
    user (val) {
      this.untouched = false
      if (val.avatar_path) {
        let base = ''
        if (process.env.NODE_ENV === 'development') {
          base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
        }
        this.prefill.avatar = base + val.avatar_path
        this.untouched = true
      }
    },
  },
  methods: {
    onChange (source, ref) {
      if (!this.$refs[ref].file) {
        return console.error('FileReader API not supported: use the <form>, Luke!')
      }
      this.images[source] = this.$refs[ref].file
      this.untouched = false
    },
    async removeImage () {
      this.$emit('delete', { user: this.user })
    },
    async submit () {
      if (!this.user || !this.user.id) {
        return
      }

      const avatar = new FormData()
      avatar.append('avatar_path', this.images.avatar)

      this.$emit('submit', { user: this.user, image: avatar })
    },
  },
}
</script>
